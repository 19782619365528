<template>
    <!-- 设置微信推送弹窗 -->
    <el-dialog
        class="logout-dialog"
        :visible.sync="dialogTableVisible"
        center
        append-to-body
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="500px"
    >
        <!-- <div class="ticket_div">
            <p>点击按钮退出登录</p>
        </div> -->
        <template slot="title">
            <div style="position: relative">
                <div style="color: #9a1f12; font-weight: 530; font-size: 24px">
                    是否退出登录
                </div>
            </div>
        </template>
        <div class="ticket_div">
            <!-- <img :src="logoutImg" width="400" /> -->
            <div
                style="
                    color: #9a1f12;
                    font-weight: 530;
                    font-size: 20px;
                    padding-top: 50px;
                    opacity: 0.8;
                "
            >
                点击确认退出登录
            </div>
            <DialogFooter
                @confirm="handleLogout"
                @cancel="closeDialog"
                confirmText="确认"
                style="padding-top: 40px; padding-bottom: 30px"
            />
        </div>
    </el-dialog>
</template>
<script>
import { logout } from "@/api/login";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    data() {
        return {
            test: "测试信息",
            ticket: "",
            qrcode: "",
            tempid: "",
            loginInterval: "",
            dialogTableVisible: false,
            logoutImg: require("@/assets/img/login/logoutBg.png"),
        };
    },
    components: { DialogFooter },
    mounted() {},
    methods: {
        init() {
            this.dialogTableVisible = true;
        },
        //获取微信二维码
        handleLogout() {
            this.$message.error("退出登录");
            localStorage.removeItem("token");
            this.removeUserId();
            this.removeOpenId();
            this.removeToken();
            this.recoverLoginId();
            logout();
            location.reload();
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },

        removeToken() {
            this.$store.commit("user/editToken", "");
            this.$store.commit("user/editRoles", []);
        },
        removeOpenId() {
            this.$store.commit("user/editOpenId", "");
        },
        removeUserId() {
            this.$store.commit("user/editUserId", "");
        },
        recoverLoginId() {
            this.$store.commit("user/editLoginId", "personal");
        },
    },
};
</script>
<style lang="stylus" scoped>
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.el-image {
  width: 30rem;
  height: 30rem;
}

.logout-dialog{
    /deep/ .el-dialog{
        background-image:url("../../assets/img/login/logoutBg.png");
        border-radius: 10px;
    }
    /deep/ .el-dialog__body{
        padding: 0;
    }
  &.el-dialog__wrapper {
    min-width: 40rem;
    left: 0;
    right: 0;
    margin:0 auto;
    .el-dialog__body{
      padding: 30px 0;
    }
  }
}
</style>
