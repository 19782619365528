<template>
    <div
        class="button"
        :style="{
            marginTop: top + 'px',
        }"
        v-bind="$attrs"
    >
        <slot>
            <el-button
                type="info"
                plain
                @click="$emit('confirm')"
                class="confirmBtn"
                :class="showTab + '_confirmBtn'"
                v-if="confirmText"
                :loading="loading"
                v-bind="confirmOptions"
                :style="{
                    order: reverse ? 2 : 1,
                }"
            >
                {{ confirmText }}
            </el-button>
            <el-button
                type="info"
                plain
                @click="$emit('cancel')"
                class="cancelBtn"
                v-if="cancelText"
                :class="showTab + '_cancelBtn'"
                :disabled="loading"
                :style="{
                    order: reverse ? 1 : 2,
                }"
                v-bind="cancelOptions"
            >
                {{ cancelText }}
            </el-button>
        </slot>
    </div>
</template>
<script>
export default {
    props: {
        confirmText: {
            type: String,
            default: "确定",
        },
        cancelText: {
            type: String,
            default: "取消",
        },
        top: {
            type: String,
            default: "20",
        },
        reverse: {
            type: Boolean,
            default: false,
        },
        showTab: {
            type: String,
            default: "NB",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        confirmOptions: {
            type: Object,
            default: () => ({}),
        },
        cancelOptions: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
<style scoped lang="stylus">
.button {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.confirmBtn {
    background-color: #de5d40 !important;
    color: white !important;
    border-radius: 7.5px;
}

.NB_confirmBtn {
    background-color: var(--NB-RED-SECOND) !important;
    border-color:var(--NB-RED-BORDER) !important;
    color: white !important;
}

.SH_confirmBtn {
    background-color: var(--SH-BLUE-SECOND) !important;
    color: white !important;
    border-color:var(--SH-BLUE-BORDER) !important;
}

.QD_confirmBtn {
    background-color: var(--QD-GREEN-SECOND) !important;
    color: white !important;
    border-color:var(--QD-GREEN-BORDER) !important;
}

.TJ_confirmBtn {
    background-color:var(--TJ-YELLOW-SECOND) !important;
    color: white !important;
    border-color:var(--TJ-YELLOW-BORDER) !important;
}

.XM_confirmBtn {
    background-color: var(--XM-PURPLE-SECOND) !important;
    color: white !important;
    border-color:var(--XM-PURPLE-BORDER) !important;
}

.SZ_confirmBtn {
    background-color: var(--SZ-ORANGE-SECOND) !important;
    color: white !important;
    border-color:var(--SZ-ORANGE-BORDER) !important;
}

.cancelBtn {
    color: white !important;
    background: linear-gradient(to right, #e5917e, #daacb9) !important;
    border-radius: 7.5px;
}

.NB_cancelBtn {
    color: white !important;
    background: linear-gradient(to right, var(--NB-RED-FIRST), var(--NB-RED-THIRD)) !important;
    border-color:var(--NB-RED-BORDER) !important;
}

.SH_cancelBtn {
    color: white !important;
    background: linear-gradient(to right, var(--SH-BLUE-FIRST), var(--SH-BLUE-THIRD)) !important;
    border-color:var(--SH-BLUE-BORDER) !important;
}

.QD_cancelBtn{
    color: white !important;
    background: linear-gradient(to right, var(--QD-GREEN-FIRST), var(--QD-GREEN-THIRD)) !important;
    border-color:var(--QD-GREEN-BORDER) !important;
}

.TJ_cancelBtn{
    color: white !important;
    background: linear-gradient(to right, var(--TJ-YELLOW-FIRST), var(--TJ-YELLOW-THIRD)) !important;
    border-color:var(--TJ-YELLOW-BORDER) !important;
}

.XM_cancelBtn{
    color: white !important;
    background: linear-gradient(to right, var(--XM-PURPLE-FIRST), var(--XM-PURPLE-THIRD)) !important;
    border-color:var(--XM-PURPLE-BORDER) !important;
}

.SZ_cancelBtn{
    color: white !important;
    background: linear-gradient(to right, var(--SZ-ORANGE-FIRST), var(--SZ-ORANGE-THIRD)) !important;
    border-color:var(--SZ-ORANGE-BORDER) !important;
}
</style>
