var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.item.hidden)?_c('div',[(!_vm.item.children || !(_vm.item.children.length > 0))?_c('el-menu-item',{staticClass:"hvr-bounce-to-bottom",attrs:{"index":_vm.resolvePath(_vm.item.route)}},[_c('div',{staticClass:"icon-contain"},[(_vm.item.icon)?_c('i',{class:_vm.item.icon}):(_vm.item.img && _vm.activePath !== _vm.item.route)?_c('img',{staticClass:"img",attrs:{"src":_vm.item.img}}):(_vm.item.imgSelect && _vm.activePath === _vm.item.route)?_c('img',{staticClass:"img",attrs:{"src":_vm.item.imgSelect}}):_vm._e()]),_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_c('el-submenu',{ref:"subMenu",attrs:{"index":_vm.resolvePath(_vm.item.children[0].route),"popper-append-to-body":""}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center"},on:{"click":function($event){return _vm.handleChildrenPush(_vm.item.children[0].route)}}},[_c('div',{staticClass:"icon-contain"},[(_vm.item.icon)?_c('i',{class:_vm.item.icon}):(
                            _vm.item.img &&
                            !_vm.item.children
                                .map((item) => item.route)
                                .includes(_vm.activePath)
                        )?_c('img',{staticClass:"img",attrs:{"src":_vm.item.img}}):(
                            _vm.item.imgSelect &&
                            _vm.item.children
                                .map((item) => item.route)
                                .includes(_vm.activePath)
                        )?_c('img',{staticClass:"img",attrs:{"src":_vm.item.imgSelect}}):_vm._e()]),_vm._v(" "+_vm._s(_vm.item.title)+" ")])]),_vm._l((_vm.item.children),function(submenu){return (!submenu.hidden)?_c('el-menu-item',{key:submenu.route,staticClass:"hvr-bounce-to-bottom",attrs:{"index":_vm.resolvePath(submenu.route)}},[_c('template',{slot:"title"},[_c('div',{staticClass:"icon-contain"},[(submenu.icon)?_c('i',{class:submenu.icon}):(
                                submenu.img && _vm.activePath !== submenu.route
                            )?_c('img',{staticClass:"img",attrs:{"src":submenu.img}}):(
                                submenu.imgSelect &&
                                _vm.activePath === submenu.route
                            )?_c('img',{staticClass:"img",attrs:{"src":submenu.imgSelect}}):_vm._e()]),_vm._v(" "+_vm._s(submenu.title)+" ")])],2):_vm._e()})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }