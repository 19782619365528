var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',_vm._b({staticClass:"normal_dialog_layout",attrs:{"visible":_vm.dialogVisible,"close-on-press-escape":false,"close-on-click-modal":false,"attach-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"confirm":()=>{
        this.$emit('confirm')
    },"close":()=>{
        this.$emit('close')
    },"cancel":()=>{
        this.$emit('cancel')
    }}},'el-dialog',_vm.$attrs,false),[_c('template',{slot:"title"},[_c('div',{staticClass:"header-title",style:({
                height: _vm.height + 'px',
            })},[_vm._t("title"),_c('img',{staticClass:"pop-right",attrs:{"src":_vm.popupRight,"alt":"","width":"250"}})],2)]),_vm._t("content")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }