var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',_vm._b({staticClass:"rocket-header-layout-dialog",attrs:{"visible":_vm.dialogVisible,"append-to-body":"","close-on-click-modal":false,"show-close":false},on:{"update:visible":function($event){_vm.dialogVisible=$event},"confirm":()=>{
        this.$emit('confirm')
    },"close":()=>{
        this.$emit('close')
    },"cancel":()=>{
        this.$emit('cancel')
    }}},'el-dialog',_vm.$attrs,false),[_c('img',{staticClass:"header-img",style:({
        top: -_vm.imgTopTrans+'px'
    }),attrs:{"src":_vm.dialogHeaderImg,"alt":"","width":"100%"}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }