<template>
    <el-dialog
        :visible.sync="dialogVisible"
        append-to-body
        :close-on-click-modal="false"
        :show-close="false"
        class="rocket-header-layout-dialog"
        v-bind="$attrs"
        @confirm="()=>{
            this.$emit('confirm')
        }"
        @close="()=>{
            this.$emit('close')
        }"
        @cancel="()=>{
            this.$emit('cancel')
        }"
    >
        <img :src="dialogHeaderImg" alt="" width="100%" class="header-img" :style="{
            top: -imgTopTrans+'px'
        }" />
        <slot></slot>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        imgTopTrans:{
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            dialogHeaderImg: require("@/assets/img/dialogHeader.png"),
        };
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit("update:visible", val);
            },
        },
    },
};
</script>
<style lang="stylus" scoped>
.rocket-header-layout-dialog{
    /deep/ .el-dialog__body{
        padding:0;
        position:relative;
    }
    /deep/ .el-dialog__header{
        display:none;
    }

    .header-img{
        position:absolute;
    }
}
</style>
