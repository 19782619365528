import { wharfCodeToStr } from "./index";
/**
 * @description 校验外部链接
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

export function isEmail(val) {
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(val);
}

export function isPhone(val) {
    //手机
    const phone = /^1[3465789]\d{9}$/;
    //固定电话
    const tel = /^0\d{2,3}-?\d{7,8}$/;
    return phone.test(val) || tel.test(val);
}

//判断输入内容只能是数字和字母，且字母自动转大写
export function setNumberFormat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("^[0-9]*$"), //正则 大于0的数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function setFloatFormat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("^[0-9.]*$"), //正则 大于0的数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

//判断输入内容只能是数字和字母，且字母自动转大写
export function setRightForrmat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

//判断输入内容只能是数字和字母,英文短杠，且字母自动转大写
export function setRightForrmat2(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9\\-]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

//邮箱，允许输入@
export function setRightEmailForrmat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9@.]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i];
            }
        }
        returnVal = str;
    }
    return returnVal;
}

//判断输入内容只能是数字和字母和英文标点和斜杠空格，且字母自动转大写
export function setRightForrmatPlus(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9$#&.,@%();':\\- /\"]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        val = replaceCN2ENSymbol(val);
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

//将字符串里中文标点替换为英文标点
export function replaceCN2ENSymbol(str) {
    return str.replace(/[‘|’]/g, '\'').replace(/[“|”]/g, '"')
        .replace(/；/g, ';').replace(/：/g, ':').replace(/。/g, '.').replace(/，/g, ',')
        .replace(/（/g,'(').replace(/）/g,')').replace(/【/g,'[').replace(/】/g,']')
        .replace(/？/g,'?').replace(/！/g,'!').replace(/《/g,'<').replace(/》/g,'>')
        .replace(/—/g,'-').replace(/ /g,' ').trim().toUpperCase();
}

//判断输入内容只能是数字和字母和空格,斜杠，且字母自动转大写
export function setRightForrmatSpace(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 /]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function setRightForrmatSpace2(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 -.]+"), //正则 英文+数字；
            len = val.length,
            str = "",
            codeReg2 = new RegExp("^(?![.]).*");
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i]) && codeReg2.test(val)) {
                if (val[i] == "-" && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "-" && val[i - 1] == "-") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == "-") {
                    str = str.substring(0, i - 1);
                }
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function setRightHistoryForrmatSpace2(val) {
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 -/.]+"), //正则 英文+数字；
            len = val.length,
            str = "",
            codeReg2 = new RegExp("^(?![.]).*");
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i]) && codeReg2.test(val)) {
                if (val[i] == "/" && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "/" && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                str += val[i].toUpperCase();
            }
        }
        val = str;
        return val;
    }
}

export function checkRightFormat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z-,]+"), //正则 英文+-；
            str = "";
        for (var i = 0; i < val.length; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function setRightForrmatSpace3(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 -/.]+"), //正则 英文+数字；
            len = val.length,
            str = "",
            codeReg2 = new RegExp("^(?![.]).*");
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i]) && codeReg2.test(val)) {
                if (val[i] == "/" && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "/" && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function formatterTime(time) {
    if (time) {
        var year = time.substr(0, 4);
        var month = time.substr(4, 2);
        var day = time.substr(6, 2);
        var hour = time.substr(8, 2);
        var min = time.substr(10, 2);
        var second = time.substr(12, 2);
        return (
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hour +
            ":" +
            min +
            ":" +
            second
        );
    }
    return "-";
}

export function getDate(time) {
    if (time) {
        return time.substr(0, 10);
    }
    return "-";
}

export function getDate2(time) {
    if (time) {
        var year = time.substr(0, 4);
        var month = time.substr(4, 2);
        var day = time.substr(6, 2);
        return year + "-" + month + "-" + day;
    }
    return "-";
}

export function getTime(time) {
    if (time) {
        return time.substr(11, 8);
    }
    return "";
}

export function getTime2(time) {
    if (time) {
        var hour = time.substr(8, 2);
        var min = time.substr(10, 2);
        var second = time.substr(12, 2);
        return hour + ":" + min + ":" + second;
    }
    return "";
}

export function getMatou(name) {
    return wharfCodeToStr(name);
}

//码放状态转成中文
export function passFlag2String(status) {
    const type = {
        Y: "已放行",
        N: "不能放行",
        T: "取消放行",
    };
    return type[status] || "未放行";
}

// 海关查验状态(不展开)
export function checkCusmovIsCheck(value) {
    const type = {
        Y: "986(机检)",
        N: "人工",
        1: "人工",
        2: "机检",
        3: "人工+机检",
        4: "机检转人工",
        5: "机检转人工（人工查验）",
        6: "前置卫生处理",
    };
    return type[value] || value || "";
}

// 历史记录删除原因
export function checkDeletedReason(value) {
    const type = {
        1: "主动删除",
        2: "离港后1个月",
        3: "添加订阅后1个月内无任何数据更新",
        4: "多方原因未完结60天",
    };
    return type[value] || "-";
}
