import Vue from "vue";
// 引入组件
import DeleteConfirmDialog from "@/components/common/DeleteConfirmDialog.vue";

/**
 * 注册删除确认对话框
 * @param {Object} options 配置项
 * @param {String} options.msg 删除提示信息
 * @param {Function} options.deleteVoid 删除方法
 * @param {Function} options.callBack 回调函数
 * @param {String} options.showType 显示类型('NB/SH等')
 * @param {String} options.successMsg 删除成功后的提示
 */
DeleteConfirmDialog.install = function (options) {
    // 创建构造器
    const DeleteConfirmBox = Vue.extend(DeleteConfirmDialog);
    // 创建组件对象，并挂载
    let instance = new DeleteConfirmBox(); // $mount(document.createElement('div') 【也可以挂载到某个元素上】

    // 将组件添加到dom中
    const id = "delete-confirm-dialog";
    const el = document.createElement("div");
    el.id = id;
    document.body.appendChild(el);

    instance.$mount(`#${id}`);

    // 调用方法或操作属性
    Vue.nextTick(() => {
        instance.open(options);
    });
};

export default DeleteConfirmDialog;
