<template>
    <div class="note-timeline">
        <RocketHeaderDilaog
            width="300px"
            top="20vh"
            :visible.sync="visibleSync"
        >
            <div class="header-title">
                <span style="margin-right: 10px; font-size: 24px">版本</span>
                更新公告
            </div>
            <el-timeline class="time-line">
                <el-timeline-item
                    v-for="(note) in notesList"
                    :key="note.key"
                    :timestamp="note.timestamp"
                    :color="note.color"
                >
                    {{ note.content }}
                    <span @click="handleToDetail(note)" class="detail-text">
                        详情
                    </span>
                </el-timeline-item>
            </el-timeline>
            <div class="btn-line">
                <el-button
                    type="danger"
                    plain
                    class="cancelBtn"
                    @click="visibleSync = false"
                >
                    关闭
                </el-button>
            </div>
        </RocketHeaderDilaog>
        <NotesDialog1 ref="notes1Ref" v-if="notes1Visible" @know="handleKnow('notes1')"></NotesDialog1>
        <NotesDialog2 ref="notes2Ref" v-if="notes2Visible" @know="handleKnow('notes2')"></NotesDialog2>
        <NotesDialog3 ref="notes3Ref" v-if="notes3Visible" @know="handleKnow('notes3')"></NotesDialog3>
        <NotesDialog4 ref="notes4Ref" v-if="notes4Visible"
        @know="handleKnow('notes4')"></NotesDialog4>
        <NotesInvite ref="notesInviteRef" v-if="notesInviteVisible"
        @know="handleKnow('notesInvite')"></NotesInvite>
    </div>
</template>
<script>
import DialogFooter from "@/components/layout/DialogFooter.vue";
import RocketHeaderDilaog from "@/components/layout/RocketHeaderDilaog.vue";
import NotesDialog1 from "./notes-1.vue";
import NotesDialog2 from "./notes-2.vue";
import NotesDialog3 from "./notes-3.vue";
import NotesDialog4 from "./notes-4.vue";
import NotesInvite from "./notes-invite.vue";
import bus from "@/utils/bus";
import { getUpdateNotesKnowConfig, saveUpdateNotesKnowConfig } from "./utils";
export default {
    name: "TimelineNote",
    components: {
        DialogFooter,
        NotesDialog1,
        NotesDialog2,
        NotesDialog3,
        NotesDialog4,
        NotesInvite,
        RocketHeaderDilaog,
    },
    data() {
        return {
            visibleSync: false,
            notesList: [
                {
                    content: "上海舱单发送",
                    timestamp: "2024-10-29",
                    key: 'notes4',
                    color: "#c11c20",
                },
                {
                    content: "客户公司管理等",
                    timestamp: "2024-10-25",
                    key: 'notes3',
                },
                {
                    content: "归大师全面升级等",
                    timestamp: "2024-08-17",
                    key: 'notes2',
                },
                {
                    content: "迎新推广活动",
                    key: 'notesInvite',
                    timestamp: "2024-07-15",
                },
                {
                    content: "新增火币功能等",
                    key: 'notes1',
                    timestamp: "2024-03-15",
                },
            ],
            dialogHeaderImg: require("@/assets/img/dialogHeader.png"),
            notes1Visible: false,
            notes2Visible: false,
            notes3Visible: false,
            notes4Visible: false,
            notesInviteVisible: false,
            curUpdateNotesKnowConfig: {},
        };
    },
    methods: {
        open() {
            this.visibleSync = true;
        },
        handleToDetail({key}) {
            this[`${key}Visible`] = true;
            this.$nextTick(() => {
                this.$refs[`${key}Ref`].open();
            });
        },
        handleKnow(key) {
            if (!this.curUpdateNotesKnowConfig[key]) {
                // 当前用户没有看过此公告，更新公告查看状态
                const cacheConfig = {
                    ...this.curUpdateNotesKnowConfig,
                    [key]: "true",
                }
                saveUpdateNotesKnowConfig(cacheConfig);
                if (key === 'notes3') {
                    // 展示新手引导
                    this.visibleSync = false;
                    this.$nextTick(() => { 
                        bus.$emit('showUpdateNotesGuide', 'notes3')
                    }) 
                }
                this.$store.commit('user/editUpdateNotesKnowConfig',cacheConfig)
            }
        },
    },
    mounted() {
        let needShowNoteKey = '';
        getUpdateNotesKnowConfig().then(res => { 
            this.curUpdateNotesKnowConfig = res;
            this.$store.commit('user/editUpdateNotesKnowConfig', res);
            const keyArr = this.notesList.map(item => item.key);
            for (let i = 0;i<keyArr.length;i++) {
                if (!res[keyArr[i]] || res[keyArr[i]] === 'false') {
                    // 当前更新公告没有展示过
                    needShowNoteKey = keyArr[i];
                    break;
                }
            }
            if (!needShowNoteKey && !res.notes3) { 
                needShowNoteKey = 'notes3';
            }
            if (!needShowNoteKey) return;
            this[`${needShowNoteKey}Visible`] = true;
            this.$nextTick(() => {
                this.$refs[`${needShowNoteKey}Ref`].open();
            });
        })
    }
};
</script>
<style lang="stylus" scoped>


.time-line{
    padding-top: 100px;
    padding-left:50px;
    user-select: none;
}

.header-title{
    text-align:center;
    font-size: 20px;
    position:absolute;
    top: -10px;
    right:20px;
    color: #fff;
    font-weight: bold;
}
.btn-line{
    display:flex;
    justify-content:center;
    margin-top: 20px;
    width : 100%;
    padding-bottom: 20px;
}
.cancelBtn {
    color: white;
    background: linear-gradient(to right, #e5917e, #daacb9);
    border-radius: 15px;
    width:120px;
}

.detail-text{
    cursor:pointer;
    color:#c11c20;
    margin-left:10px;
}
</style>
