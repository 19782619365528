<template>
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>版本更新</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line text">
                    为了给您提供更优质的服务，放舱网于 2024 年 10 月 25 日进行了更新。
                </div>
                <div class="line bold">
                    1.订阅列表增加客户公司绑定，用于提单号跟客户公司做关联，方便分类管理。
                </div>
                <div class="line">列表新增客户公司列，可在此绑定客户公司</div>
                <img src="./imgs/notes-3/1.jpg" />
                <div class="line">客户公司选择弹窗，您也可在此管理客户公司数据</div>
                <img src="./imgs/notes-3/2.jpg" />
                <div class="line">绑定客户公司后可在此处进行筛选</div>
                <img src="./imgs/notes-3/3.jpg" />
                <div class="line bold">
                    2.增加提单号订阅反馈功能，在每个提单号后面有一个按钮，点击后可给放舱网后台反馈异常情况，如状态没更新、回执错误等。
                </div>
                <img src="./imgs/notes-3/4.jpg" />
                <div class="line">在此输入此票存在问题，我们会及时为您处理，您也可以在此查看处理进度</div>
                <img src="./imgs/notes-3/5.jpg"/>
                <div class="line bold">
                    3.<span class="bold">【宁波港】</span>增加换船标识，可以手动设置是否换船，可提高换船后的数据跟踪准确性。
                </div>
                <img src="./imgs/notes-3/6.jpg"/>
                <div class="line bold">
                    4.<span class="bold">【宁波港】</span>码头修改功能，解决码头存在多船期导致码头数据不准确的问题。
                </div>
                <img src="./imgs/notes-3/7.jpg"/>
                <img src="./imgs/notes-3/8.jpg"/>
                <div class="line text">
                    最后感谢各位用户一如即往的支持，也请大家多多提宝贵意见，放舱网也会继续始终如
                    一地为大家提供优质的服务。祝愿各位一切顺利。
                </div>
                <div class="replenish-info">
                    <div class="content">
                        <span>放舱网</span>
                        <span>2024年10月25日</span>
                    </div>
                </div>
            </div>
        </div>
    </NotesDialog>
</template>
<script>
import NotesDialog from './NotesDialog.vue';
export default {
    components: {
        NotesDialog,
    },
    data() {
        return {
            visibleSync: false,
        };
    },
    methods: {
        open() {
            this.visibleSync = true;
        },
        handleKnow() {
            this.visibleSync = false;
            this.$emit('know');
        },
    },
}
</script>