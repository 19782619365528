var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({staticClass:"button",style:({
        marginTop: _vm.top + 'px',
    })},'div',_vm.$attrs,false),[_vm._t("default",function(){return [(_vm.confirmText)?_c('el-button',_vm._b({staticClass:"confirmBtn",class:_vm.showTab + '_confirmBtn',style:({
                order: _vm.reverse ? 2 : 1,
            }),attrs:{"type":"info","plain":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('confirm')}}},'el-button',_vm.confirmOptions,false),[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]):_vm._e(),(_vm.cancelText)?_c('el-button',_vm._b({staticClass:"cancelBtn",class:_vm.showTab + '_cancelBtn',style:({
                order: _vm.reverse ? 1 : 2,
            }),attrs:{"type":"info","plain":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancel')}}},'el-button',_vm.cancelOptions,false),[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }