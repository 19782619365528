const FANG_URL = "http://192.168.1.53:8111/";
const XUAN_URL = "http://192.168.1.47:8111";
const YUAN_URL = "http://192.168.1.92:8111";
const XU_URL = "http://192.168.1.65:8111";
const LOCAL_URL = "http://localhost:8111";
const DEV_SITE_URL = "http://124.223.173.176:8111/";
const WANG_URL = "http://192.168.1.82:8111";
const TEST_URL = FANG_URL;
// 用于前端需要同时开启多个服务时，可以使用,可以分别npm run dev 和 npm run test分别启动连接不同后端服务的前端服务
const DEV_URL = DEV_SITE_URL;

// api文件夹下接口配置
export const baseEnum = {
    production: "https://prod-api.fangcangwang.com/",
    test: TEST_URL,
    // test: "https://prod-api.fangcangwang.com/",
    // development: "http://124.223.173.176:8111/",
    development: DEV_URL,
};

// axios文件夹下接口配置
export const axiosEnum = {
    // production: "https://fcw.fangcangwang.com/",
    production: "https://prod-api.fangcangwang.com/",
    test: TEST_URL,
    // test: "https://prod-api.fangcangwang.com/",
    // development: "http://124.223.173.176:8111/",
    development: DEV_URL,
};

// 管理员登录
export const axios3Enum = {
    production: "https://fcw.fangcangwang.com/api/private/",
    // test: "https://prod-api.fangcangwang.com/",
    test: TEST_URL,
    // development: "http://124.223.173.176:8111/",
    development: DEV_URL,
};

// 宁波舱单
export const axios4Enum = {
    production: "https://fcw.fangcangwang.com/nbManifest/",
    test: `${TEST_URL}/nbManifest/`,
    // development: "http://124.223.173.176:8111/nbManifest/",
    development: `${DEV_URL}/nbManifest/`,
};

// 宁波舱单导出
export const axios8Enum = {
    production: "https://prod-api.fangcangwang.com/",
    test: `${TEST_URL}/manifest/`,
    // development: "http://116.62.132.32:8186/manifest/",
    development: `${DEV_URL}/manifest/`,
};

// 上海舱单
export const axios5Enum = {
    production: "https://prod-api.fangcangwang.com/shManifest/",
    test: `${TEST_URL}/shManifest/`,
    // development: "http://124.223.173.176:8111/shManifest/",
    development: `${DEV_URL}/shManifest/`,
};

// VGM
export const axiosVGMEnum = {
    production: "http://192.168.1.42:8086/manifest/",
    test: `${TEST_URL}/vgm/`,
    // development: "http://124.223.173.176:8111/vgm/",
    development: `${DEV_URL}/vgm/`,
};

// 预录单
export const axios6Enum = {
    production: "http://47.110.59.188:8081/",
    test: "http://192.168.1.30:10001/",
    development: "http://124.223.173.176:10001/",
};

// 支付
export const axios7Enum = {
    production: "https://prod-api.fangcangwang.com/",
    test: TEST_URL,
    // test: "https://prod-api.fangcangwang.com/",
    // development: "http://124.223.173.176:8111",
    development: DEV_URL,
};

export const axiosEnum_JDWX = {
    production: "https://jd-wx-program.nbhuojian.com/",
    test: "https://jd-wx-program.nbhuojian.com/",
    development: "https://jd-wx-program.nbhuojian.com/",
};

export const SHIP_SEARCH_URL = "https://searchv3.shipxy.com";

export const GPS_SEARCH = "https://dataq.4portun.com:81";
