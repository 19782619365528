import { getShipCompanyAndAgent } from "@/api/sh-manifest";
import { getHsCodeCategoryTree } from "@/api/analysis";
import { apiCallback } from "@/utils";
import { importTxtDataToJSON } from "@/utils/txtdata";
import { getShipAgent, getShipCompany } from "../../api/sh-manifest";

const state = {
    documentsAttachedList: [], // 监管条件（随附单证）
    shipCompany: [], // 船公司列表（海管家获取）
    shipAgent: [], // 船代列表（海管家获取）
    hscodeCategory: [], // hscode类目数据
};

const mutations = {
    editDocumentsAttachedList(state, payload) {
        state.documentsAttachedList = payload.documentsAttachedList;
    },
    editShipAgent(state, payload) {
        state.shipAgent = payload;
    },
    editShipCompany(state, payload) {
        state.shipCompany = payload;
    },
    editHscodeCategory(state, payload) {
        state.hscodeCategory = payload;
    },
};

const actions = {
    updateDocumentsAttachedList({ commit }) {
        return new Promise((resolve) => {
            importTxtDataToJSON().then((res) => {
                commit("editDocumentsAttachedList", res);
                resolve();
            });
        });
    },
    updateShipCompanyAndAgent({ commit }) {
        return new Promise((resolve) => {
            getShipCompanyAndAgent().then((res) => {
                const { shipAgency, shipCompany } = apiCallback(res);
                const cacheShipAgency = shipAgency.map((item) => {
                    return {
                        label: item.dictKey,
                        value: item.id,
                    };
                });
                const cacheShipCompany = shipCompany.map((item) => {
                    return {
                        label: item.dictKey,
                        value: item.id,
                    };
                });
                commit("editShipAgent", cacheShipAgency);
                commit("editShipCompany", cacheShipCompany);
                resolve(res);
            });
        });
    },
    updateShipAgent({ commit }) {
        return new Promise((resolve) => {
            getShipAgent().then((res) => {
                const data = apiCallback(res);
                const shipAgent = data.map((item) => {
                    return {
                        label: item.dictKey,
                        value: item.id,
                    };
                });
                commit("editShipAgent", shipAgent);
                resolve(res);
            });
        });
    },
    updateShipCompany({ commit }) {
        return new Promise((resolve) => {
            getShipCompany().then((res) => {
                const data = apiCallback(res);
                const shipCompany = data.map((item) => {
                    return {
                        label: item.dictKey,
                        value: item.id,
                    };
                });
                commit("editShipCompany", shipCompany);
                resolve(res);
            });
        });
    },
    updateHscodeCategoryData({ commit }) {
        return new Promise((resolve) => {
            getHsCodeCategoryTree().then((res) => {
                const data = apiCallback(res);
                commit("editHscodeCategory", data);
                resolve(data);
            });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
