import md5 from "js-md5";
import { getCurrentUser } from "@/api";
import { apiCallback } from "@/utils";

const state = {
    userId: "",
    openId: "",
    loginState: "",
    token: "",
    username: "",
    userphone: "",
    qqNumber: "",
    avatar: "",
    loginId: "personal", //登陆身份：认证企业或个人
    uAccount: 0,
    cAccount: 0,
    accountUser: null,
    accountCompany: null,
    roles: [],
    cid: null,
    inviteCode: "",
    clientUseComapnyList: [], //客户公司可用列表
    updateNotesKnowConfig: {}, // 更新公告已读配置
};

const mutations = {
    // 设置userId
    editUserId(state, payload) {
        state.userId = payload;
    },
    editOpenId(state, payload) {
        state.openId = payload;
    },
    // 设置登录状态
    editLoginState(state, payload) {
        state.loginState = payload;
    },
    editToken(state, payload) {
        state.token = payload;
    },
    editUsername(state, payload) {
        state.username = payload;
    },
    editUserphone(state, payload) {
        state.userphone = payload;
    },
    editQQNumber(state, payload) {
        state.qqNumber = payload;
    },
    editAvatar(state, payload) {
        state.avatar = payload;
    },
    editLoginId(state, payload) {
        state.loginId = payload;
    },
    edituAccount(state, payload) {
        state.uAccount = payload;
    },
    editcAccount(state, payload) {
        state.cAccount = payload;
    },
    editAccountUser(state, payload) {
        state.accountUser = payload;
        state.uAccount = payload.balance;
    },
    editAccountCompany(state, payload) {
        state.accountCompany = payload;
        state.cAccount = payload.balance;
    },
    editCid(state, payload) {
        state.cid = payload;
    },
    editInviteCode(state, payload) {
        state.inviteCode = payload;
    },
    editRoles(state, payload) {
        state.roles = payload;
    },
    editClientUseComapnyList(state, payload) {
        state.clientUseComapnyList = payload;
    },
    editUpdateNotesKnowConfig(state, payload) {
        state.updateNotesKnowConfig = payload;
    },
};

const actions = {
    updateUserInfo({ state, commit }) {
        return new Promise((resolve, reject) => {
            getCurrentUser()
                .then((res) => {
                    const data = apiCallback(res);
                    const {
                        userName,
                        phone,
                        avatar,
                        id,
                        role,
                        token,
                        invitationCode,
                        roles,
                    } = data || {};
                    var md5Role = md5(role + "/" + id);
                    var localtype = md5("role");
                    localStorage.setItem(localtype, md5Role);
                    if (!state.token) {
                        commit("editToken", token);
                    }
                    if (!state.userId) {
                        commit("editUserId", id);
                    }
                    commit("editInviteCode", invitationCode);
                    commit("editUsername", userName);
                    commit("editUserphone", phone);
                    commit("editRoles", roles);
                    var imageUrl = avatar
                        ? avatar
                        : require("@/assets/img/icon_user.png");
                    commit("editAvatar", imageUrl);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateInviteCode({ commit }) {
        const breakPathArr = ["wxtruckmap", "wxshipmap", "wxlogin"]; // 小程序界面，不需要更新邀请码
        const needBreak = breakPathArr.some((path) => {
            return window.location.href.indexOf(path) !== -1;
        });
        if (!needBreak) {
            getCurrentUser().then((res) => {
                const data = apiCallback(res);
                commit("editRoles", data.roles);
                commit("editInviteCode", data.invitationCode);
            });
        }
    },
};

const getters = {
    clientCompanyListByName(state) {
        return (name) => {
            //根据公司名称查询公司列表
            return name
                ? state.clientUseComapnyList.filter((item) => {
                      return item.clientName.indexOf(name) !== -1;
                  })
                : state.clientUseComapnyList;
        };
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
