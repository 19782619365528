<template>
    <el-dialog
        :visible.sync="dialogVisible"
        append-to-body
        :close-on-click-modal="false"
        :show-close="false"
        width="300px"
        class="rocket-header-layout-dialog"
        top="20vh"
    >
        <img
            :src="dialogHeaderImg"
            alt=""
            width="100%"
            class="header-img"
            :style="{
                top: -100 + 'px',
            }"
        />
        <div
            class="header-title"
            :style="{
                right: 100 - (title.length - 2) * 15 + 'px',
            }"
        >
            <span style="font-size: 24px">{{ title }}</span>
        </div>
        <div class="content">
            {{ msg }}
        </div>
        <DialogFooter
            class="footer"
            :confirmText="confirmText"
            :cancelText="btnText"
            @confirm="dialogVisible = false"
            @cancel="handleCancel"
            reverse
        />
    </el-dialog>
</template>
<script>
import DialogFooter from "../layout/DialogFooter.vue";
export default {
    data() {
        return {
            dialogHeaderImg: require("@/assets/img/dialogHeader.png"),
            dialogVisible: false,
            msg: "",
            callBack: null,
            title: "提示",
            btnText: "我知道了",
            confirmText: "",
        };
    },
    components: {
        DialogFooter,
    },
    methods: {
        open({
            msg,
            callBack = null,
            title,
            btnText = "我知道了",
            needCancel = false,
        }) {
            this.msg = msg;
            this.callBack = callBack;
            this.title = title || "提示";
            this.btnText = btnText;
            this.confirmText = needCancel ? "取消" : "";
            // 等dom中图片加载完成再显示弹窗
            this.$nextTick(() => {
                this.dialogVisible = true;
            });
        },
        handleCancel() {
            this.dialogVisible = false;
            this.callBack && this.callBack();
        },
    },
};
</script>
<style lang="stylus" scoped>
.rocket-header-layout-dialog{
    /deep/ .el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-dialog__body{
        padding:0;
        position:relative
    }
    /deep/ .el-dialog__header{
        display:none;
    }

    .header-img{
        position:absolute;
    }
    .header-title{
        text-align:center;
        font-size: 20px;
        position:absolute;
        top: -10px;
        color: #fff;
        font-weight: bold;
    }
    .content{
        display:flex;
        justify-content:center;
        padding-top: 100px;
        padding-bottom:20px;
        user-select: none;
        font-size: 16px;
        color: #B87100;
    }
    .footer{
        padding-bottom: 20px;
    }
}
</style>
