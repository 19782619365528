// 客户公司管理
import { request } from "@/utils/request.js";

/**
 * @description 获取客户公司列表
 * @param {Number} pageNo
 * @param {Number} pageSize
 * @param {String} name
 */
export function getClientCompanyList(param) {
    return request(
        "/blno/userBlno/getClientCompanys",
        "post",
        param,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * @description 停用客户公司
 * @param {Number} id
 */
export function disableClientCompany(param) {
    return request(
        "/blno/userBlno/delCompany",
        "post",
        param,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * @description 添加客户公司
 * @param {String }clientName	客户名称
 * @param {String} contacts	联系人
 * @param {String} contactsPhone	联系人手机
 * @param {String} remark	备注
 */
export function addClientCompany(param) {
    return request("/blno/userBlno/addClientCompany", "post", param);
}

/**
 * @description 设置客户公司
 * @param {Number} blnoId
 * @param {Number} companyId
 * @param {String} companyName
 */

export function setClientCompany(param) {
    return request(
        "/blno/userBlno/setClientCompany",
        "post",
        param,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}
