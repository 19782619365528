<template>
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>版本更新</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line">为了给您提供更优质的服务，放舱网于 2024 年 10 月 29 日进行了更新。</div>
                <div class="line bold">更新内容：</div>
                <div class="line">新增上海预配舱单发送功能，支持海管家模版导入，保存舱单自动校验船期，最快5分钟就可获取海关和船代回执</div>
                <div class="line bold">开通方法:</div>
                <div class="line">本功能需线下签约开通后使用，开通方式如下图所示：</div>
                <img src="./imgs/notes-4/1.jpg"/>
                <div class="line bold">功能页面截图：</div>
                <img src="./imgs/notes-4/2.jpg"/>
            </div>
        </div>
    </NotesDialog>
</template>

<script lang="ts">
import NotesDialog from './NotesDialog.vue';

export default {
    components: {
        NotesDialog,
    },
    data() { 
        return {
            visibleSync: false
        }
    },
    methods: {
        open() { 
            this.visibleSync = true
        },
        handleKnow() { 
            this.visibleSync = false;
            this.$emit('know')
        }
    }
}
</script>