// 生成动态路由
import Router from "vue-router";
import getPageTitle from "./get-page-title";
import {queryPreSheetByUserId} from "@/api/preSheet";
import {router, constantRoutes} from "../router";
import {checkRole} from "./index";
import store from "../store";
import ro from "element-ui/src/locale/lang/ro";

let routes = [...constantRoutes];
//定义变量判断是否已经动态添加过，如果刷新后load永远为 0
let load = 0;
// 提供一个重置路由的方法
export const resetRouter = () => {
    router.matcher = new Router({
        mode: "history",
        form: process.env.BASE_URL,
        routes,
    });
};

const setRouter = (routes) => {
    router.matcher = new Router({
        mode: "history",
        form: process.env.BASE_URL,
        routes,
    });
};

// 处理预录单路由逻辑
export function preSheetRouter(preSheetRouters) {
    // 如果所有的数据都有了公司全名，那么路由数据更新
    if (preSheetRouters.every((item) => item.company)) {
        // 选出preSheet路由
        const filterRouteIndex = store.state.permission.menuRoutes.findIndex(
            (item) => item.name === "PreSheet",
        );
        // 打造全新路由
        const childrenRoutes = preSheetRouters.map((item) => ({
            path: `preSheet/${item.agentCode}`,
            name: `${item.agentCode}`,
            meta: {title: `${item.shortName}`},
        }));
        const paramRoute = {
            ...store.state.permission.menuRoutes[filterRouteIndex],
            hidden: !preSheetRouters.length,
            children: childrenRoutes,
        };
        const newMenu = [...store.state.permission.menuRoutes];
        newMenu[filterRouteIndex] = paramRoute;
        store.commit("permission/SET_MEAU_ROUTES", newMenu);
    }
}

//导航守卫
router.beforeEach(async (to, from, next) => {
    // 设置页面标题
    document.title = getPageTitle(to.meta.title);
    //非登录、有菜单数据、 没有进行添加（或者刷新了）
    if (load === 0) {
        load++;
        //再次调用存储菜单数据(前提是在存储的地方有调用添加路由规则)、或者直接调用动态添加路由规则事件
        // 生成路由，根据权限
        const isAdmin = checkRole("admin");
        // 处理预录单路由(登录有即可展示)
        // store.state.user.token && handlePreSheetRouter();
        // 生成异步路由(目前只有admin和staff权限)
        const accessedRoute = await store.dispatch(
            "permission/generateRoutes",
            [isAdmin ? "admin" : "staff"],
        );
        routes = [...routes, ...accessedRoute];
        setRouter(routes);
        next({...to, replace: true});
    } else {
        // 物流实时查询模块符合条件无需登录也可进入，而其他模块需要登录，这里处理从物流实时查询跳转其他页面逻辑
        !store.state.user.token && setTimeout(() => {
            if (from.fullPath.includes('boxquery')) {
                loginDialog()
            }
        }, 500)
        next();
        // 预录单模块无需登录也可进入，而其他模块需要登录，这里处理从预录单跳转其他页面逻辑
        !store.state.user.token &&
        setTimeout(() => {
            if (
                from.fullPath.includes("preSheet") &&
                to.fullPath !== "401"
            ) {
                loginDialog();
            }
        }, 500);
    }
});

// 处理预录单路由
function handlePreSheetRouter() {
    queryPreSheetByUserId().then(({data}) => {
        if (data.data && data.data.constructor == Array) {
            preSheetRouter(data.data);
        }
    });
}

export default {
    install(Vue) {
        Vue.prototype.checkPer = (value) => {
            if (value && value instanceof Array && value.length > 0) {
                const roles = store.state.user && store.state.user.roles
                const permissionRoles = value
                if (roles) {
                    // console.log('roles==',roles);

                    return roles.some(role => {
                        return permissionRoles.includes(role)
                    })
                } else {
                    return false;
                }
            } else {
                console.error(`need roles! Like v-permission="['admin','editor']"`)
                return false
            }
        }
    }
}
