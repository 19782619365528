import "@/utils/import";
import Vue from "vue";
import "@/utils/permission";
import App from "./App.vue";
import { router } from "./router/index.js";
import store from "@/store";
// 权限指令
import checkPer from '@/utils/permission'

Vue.config.productionTip = false;
window.$store = store;
window.$router = router;


Vue.use(checkPer)


new Vue({
    router,
    store,
    render: (h) => h(App),
    created: function () {
        const storageToken = localStorage.getItem("token");
        this.$store.commit("user/editToken", storageToken);
        this.$store.dispatch("user/updateInviteCode");
    },
}).$mount("#app");
