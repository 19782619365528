// 菜单栏
import Layout from "@/components/layout/Layout";
import shanghai from "./shanghai";
import vgm from "@/router/modules/vgm";
export default {
    path: "/",
    component: Layout,
    // 重定向
    redirect: "home",
    children: [
        {
            path: "logistics",
            component: () => import("@/views/Logistics"),
            name: "Logistics",
            meta: { title: "物流跟踪及订阅" },
        },
        {
            path: "shipSubscrib",
            name: "ShipSubscrib",
            component: () => import("@/views/ShipSubscrib"),
            meta: { title: "船期订阅" },
        },
        {
            path: "truckSubscribe",
            name: "TruckSubscribe",
            component: () => import("@/views/TruckSubscribe"),
            meta: { title: "集卡定位" },
        },
        {
            path: "boxquery",
            name: "BoxQuery",
            component: () => import("@/views/BoxQuery"),
            meta: { title: "物流可视化" },
        },
        {
            path: "manifestQuery",
            name: "ManifestQuery",
            component: () => import("@/views/ManifestQuery"),
            meta: { title: "舱单查询" },
        },
        // {
        //     path: "boxDesignated",
        //     name: "boxDesignated",
        //     component: () => import("@/views/DesignatedBox"),
        //     meta: {
        //         title: "指定箱",
        //     },
        // },
        // {
        //     path: "shManifestSend",
        //     name: "SHManifestSend",
        //     component: () => import("@/views/SHManifestSend"),
        //     meta: { title: "舱单资料发送" },
        // },
        {
            path: "history",
            name: "History",
            component: () => import("@/views/HistoryBox/HistoryBox"),
            meta: { title: "历史记录" },
        },
        //舱单发送
        // {
        //     path: "/manifestSend",
        //     name: "manifestSend",
        //     component: () => import("@/views/ManifestSend"),
        //     meta: { title: "舱单委托录入" },
        // },
        // {
        //     path: "/manifestEdit",
        //     name: "ManifestEdit",
        //     component: () =>
        //         import(
        //             "@/views/ManifestSend/components/ManifestEditor/manifest.vue"
        //         ),
        //     meta: { title: "舱单编辑" },
        //     hidden: true,
        // },
        // {
        //     path: "VGM",
        //     component: () => import("@/views/VGM"),
        //     meta: { title: "VGM发送", hiddenChild: true },
        //     children: vgm,
        // },
        {
            path: "sh_manifest",
            name: "sh_manifest",
            component: () => import("@/views/ShanghaiManifest"),
            meta: { title: "上海舱单发送", hiddenChild: true },
            children: shanghai,
        },
        // // 通过store中的menu方法手动添加子元素,不要随意改动这个预录单路由name，和预录单路由展示相关联
        // {
        //     path: "/preSheet/:id",
        //     name: "PreSheet",
        //     component: () => import("@/views/PreSheet/PreSheet"),
        //     meta: { title: "预录单" },
        //     hidden: true,
        // },
        {
            path: "classAnalysis",
            name: "ClassAnalysis",
            component: () => import("@/views/ClassAnalysis"),
            meta: { title: "归大师" },
        },
        {
            path: "advertisement",
            name: "Advertisement",
            component: () => import("@/views/AdvFront/Advertisement"),
            meta: { title: "供应商目录" },
        },
        // {
        //     path: "orderBox",
        //     name: "OrderBox",
        //     component: () => import("@/views/OrderBox"),
        //     meta: { title: "订提箱" },
        // },
        // {
        //     path: "fileAnalysis",
        //     name: "FileAnalysis",
        //     component: () => import("@/views/FileAnalysis/index.vue"),
        //     meta: { title: "资料识别" },
        // },
        // {
        //     path: "businessCooperation",
        //     name: "BusinessCooperation",
        //     component: () => import("@/views/BusinessCooperation/index.vue"),
        //     meta: { title: "商务合作" },
        // },
    ],
};
