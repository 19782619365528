import { saveConfig, getConfig } from "@/api/common";
import store from "@/store";

export const getUpdateNotesKnowConfig = () => {
    return new Promise(async (resolve) => {
        const { userId } = store.state.user;
        const dictKey = `update_notes_know_${userId}`;
        const isKnowNoteOld1 = localStorage.getItem("isKnowNotes-1");
        const isKnowNoteOld2 = localStorage.getItem("isKnowNotes-2");
        const isKnowNotesInvite = localStorage.getItem("isKnowNotes-invite");
        try {
            const configRes = await getConfig({ dictKey });
            const { dictValue } = configRes.data.data;
            const cacheConfig = JSON.parse(dictValue);
            resolve({
                ...cacheConfig,
                notes1: cacheConfig.notes1 || isKnowNoteOld1,
                notes2: cacheConfig.notes2 || isKnowNoteOld2,
                notesInvite: cacheConfig.notesInvite || isKnowNotesInvite,
            });
        } catch (err) {
            // 为了兼容之前的更新公告读取情况
            resolve({
                notes1: isKnowNoteOld1,
                notes2: isKnowNoteOld2,
                notesInvite: isKnowNotesInvite,
            });
        }
    });
};

export const saveUpdateNotesKnowConfig = async (value) => {
    const { userId } = store.state.user;
    const dictKey = `update_notes_know_${userId}`;
    return await saveConfig({
        dictKey,
        dictValue: typeof value === "string" ? value : JSON.stringify(value),
    });
};
